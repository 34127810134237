$colors: "primary"#03A7EA, "secondary"#28419F;

$primary: #03A7EA;
$secondary: #28419F;

@each $name, $color in $colors {
    .bg-#{$name} {
        background: $color;
    }

    .text-#{$name} {
        color: $color;
    }

    .border-#{$name} {
        border-color: $color;
    }
}