.image-card {
    &--small {
        width: 32rem;
    }

    h6 {
        @apply text-xl font-semibold uppercase opacity-60;
    }

    h1 {
        @apply text-5xl font-bold mt-2 mb-8;
    }

    h2 {
        @apply text-4xl font-bold mb-5 mt-2;
    }

    h3 {
        @apply text-3xl font-bold;
    }

    p {
        @apply text-xl mb-6 leading-normal;

        &:last-child {
            @apply mb-0;
        }
    }
}
