@tailwind base;
@tailwind components;
@tailwind utilities;

// Base
@import "partials/alpine";
@import 'partials/variables';
@import 'partials/image-card';

html {
    background: white;

    @media (min-width: 2880px) {
        font-size: 24px;
    }

    @media (min-width: 3800px) {
        font-size: 32px;
    }
}

body {
    background: rgba(0, 0, 0, 0.06);
}

.app-content {
    @apply pt-32 h-screen flex flex-col;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    width: 120rem;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.custom-player {
    iframe {
        width: 100%;
        height: 100%;
    }
}

.blog-content {
    max-width: 60rem;
    @apply mx-auto;
}

.blog-content-carousel {
    .carousel__pagination-button {
        @apply h-1 w-3 m-1;
    }

    .carousel__pagination {
        @apply absolute z-10 w-full mt-3;
    }

    .carousel__pagination-button {
        background: rgba($primary, 0.4);
    }

    .carousel__pagination-button--active {
        background: $primary;
    }
}
